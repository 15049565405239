*
  padding: 0
  margin: 0
  box-sizing: border-box

body
  font-family: sans-serif

.flex-row
  display: flex
  flex-direction: row
.flex-col
  display: flex
  flex-direction: column

.prototype
  @extend .flex-row
  gap: 1rem
  padding: 1rem

.column
  @extend .flex-col
  gap: 1rem

.control-cube,
.control-plot
  @extend .flex-col
  gap: 1rem
  min-width: 256px

.cube-select-dimension-elements
  @extend .flex-col

  user-select: none
  cursor: pointer

  .head
    @extend .flex-row
    align-items: stretch

    .control
      @extend .flex-row
      align-items: center

      padding: 0.5rem
      background: #bfbfbf

    .caption
      @extend .flex-row
      align-items: center
      justify-content: space-between
      gap: 1rem

      flex-grow: 1
      padding: 0.5rem
      background: #cfcfcf

  .body
    @extend .flex-col
    li
      @extend .flex-row
      align-items: center
      gap: 0.5rem

      margin-top: 0.25rem
      padding: 0.5rem
      background: #efefef
    li:hover
      background: #dfdfdf

.cube-select-facts
  @extend .flex-col

  user-select: none
  cursor: pointer

  li
    @extend .flex-row
    align-items: center
    gap: 0.5rem

    padding: 0.5rem
    background: #efefef

  li:hover
    background: #dfdfdf

svg
  font-size: x-small
  text-transform: uppercase
  display: block
  overflow: visible

.select-axis
  @extend .flex-col
  user-select: none
  cursor: pointer

  li
    @extend .flex-row
    align-items: center
    padding: 0.5rem
    background: #efefef
    gap: 0.5rem

  li:hover
    background: #dfdfdf

.plot
  @extend .flex-col
  gap: 1rem
  flex-grow: 1
  width: 100%
  justify-content: center

html
  @extend .flex-col
  min-height: 100%

body
  @extend .flex-col
  flex-grow: 1

.prototype
  flex-grow: 1
  
