* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: sans-serif;
}

.flex-row, .select-axis li, .cube-select-facts li, .cube-select-dimension-elements .body li, .cube-select-dimension-elements .head .caption, .cube-select-dimension-elements .head .control, .cube-select-dimension-elements .head, .prototype {
  flex-direction: row;
  display: flex;
}

.flex-col, body, html, .plot, .select-axis, .cube-select-facts, .cube-select-dimension-elements .body, .cube-select-dimension-elements, .control-cube, .control-plot, .column {
  flex-direction: column;
  display: flex;
}

.prototype {
  gap: 1rem;
  padding: 1rem;
}

.column {
  gap: 1rem;
}

.control-cube, .control-plot {
  gap: 1rem;
  min-width: 256px;
}

.cube-select-dimension-elements {
  user-select: none;
  cursor: pointer;
}

.cube-select-dimension-elements .head {
  align-items: stretch;
}

.cube-select-dimension-elements .head .control {
  background: #bfbfbf;
  align-items: center;
  padding: .5rem;
}

.cube-select-dimension-elements .head .caption {
  background: #cfcfcf;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: .5rem;
}

.cube-select-dimension-elements .body li {
  background: #efefef;
  align-items: center;
  gap: .5rem;
  margin-top: .25rem;
  padding: .5rem;
}

.cube-select-dimension-elements .body li:hover {
  background: #dfdfdf;
}

.cube-select-facts {
  user-select: none;
  cursor: pointer;
}

.cube-select-facts li {
  background: #efefef;
  align-items: center;
  gap: .5rem;
  padding: .5rem;
}

.cube-select-facts li:hover {
  background: #dfdfdf;
}

svg {
  text-transform: uppercase;
  font-size: x-small;
  display: block;
  overflow: visible;
}

.select-axis {
  user-select: none;
  cursor: pointer;
}

.select-axis li {
  background: #efefef;
  align-items: center;
  gap: .5rem;
  padding: .5rem;
}

.select-axis li:hover {
  background: #dfdfdf;
}

.plot {
  flex-grow: 1;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}

html {
  min-height: 100%;
}

body, .prototype {
  flex-grow: 1;
}

/*# sourceMappingURL=index.11c1ec4a.css.map */
